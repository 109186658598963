import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Image,
  Input,
  useToast,
  useOutsideClick,
  Icon,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalContent,
  Avatar,
  Link,
  Box,
  Grid,
  GridItem,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

import "../../components/css/dateRangePicker.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "swiper/css/navigation";
import "swiper/css";
import "./customNavigation.css";
import "swiper/css/effect-fade";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CreateCommentDocument,
  GetmeDocument,
  ListInvestmentDocument,
  RemoveCommentDocument,
  SiteDocument,
  UpdateCommentDocument,
} from "../../graphql/generated";

import {
  IPjDetailNavProps,
  typeOfSiteColor,
  typeOfSiteText,
} from "../../type/IProjectInterface";
import { Helmet } from "react-helmet-async";

import { ReactComponent as PDFIcon } from "src/icons/general/pdfIcon.svg";
import { ReactComponent as DarkCloseBtn } from "src/icons/general/darkCloseBtn.svg";
import { ReactComponent as WhiteCloseBtn } from "src/icons/general/whiteCloseBtn.svg";

import { ReactComponent as SendIcon } from "src/icons/general/sendLetterIcon.svg";
import { ReactComponent as TrashIcon } from "src/icons/general/trashIcon.svg";
import { ReactComponent as UploadAttachIcon } from "src/icons/general/uploadAttachIcon.svg";

import {
  FiBell,
  FiArrowLeft,
  FiSearch,
  FiEdit3,
  FiHome,
  FiFlag,
} from "react-icons/fi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { BsLightning } from "react-icons/bs";
import DocumentSwiper from "src/components/DocumentSwiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFlip, EffectFade } from "swiper";
import {
  returnByTheme,
  returnByColor,
  convertAsCurrPjStatus,
  getCurrProjectStatus,
  getCurrStatusDisplayName,
  convertAsAvatarTitle,
  isEmptyString,
  uploadDoc,
  randomBgColor,
} from "../../utils/util";
import ProjectCalcOverviewSection from "./projectDetailComponent/ProjectCalcOverviewSection";
import ProjectSharingPieChart from "./projectDetailComponent/ProjectSharingPieChart";
import ProjectProgressBarSection from "./projectDetailComponent/ProjectProgressBarSection";
import ProjectPowerGenSection from "./projectDetailComponent/ProjectPowerGenSection";
import ProjectInvestorSection from "./projectDetailComponent/ProjectInvestorSection";
import ProjectStaffSection from "./projectDetailComponent/ProjectStaffSection";
import { isNaN } from "lodash";
import ProjectCalculatorPanel from "./projectDetailComponent/ProjectCalculatorPanel";
import RightCornerTool from "src/components/RightCornerTool";

const ProjectDetail = () => {
  const [currIndex, setCurrIndex] = useState(0);
  const [currPjStatus, setCurrPjStatus] = useState("");
  const [currPjStatusBarObj, setCurrPjStatusBarObj] = useState<any>();
  const [currentTab, setCurrentTab] = useState("All");
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("current");
  const [showImagesGallery, setShowImagesGallery] = useState(false);
  const [showDocumentsGallery, setShowDocumentsGallery] = useState(false);
  const [imageGalleryIndex, setImageGalleryIndxe] = useState(0);
  const [documentGalleryIndex, setDocumentGalleryIndxe] = useState(0);
  const [showComment, setShowComment] = useState({ index: -1, show: false });
  const [tempComment, setTempComment] = useState("");
  const [commentsList, setCommentList] = useState<any>();
  const [uploadRefIndex, setUploadRefIndex] = useState(0);
  const [calcData, setCalcData] = useState<any>(null);
  const [showAttachment, setShowAttachment] = useState({
    index: -1,
    show: false,
  });

  const [showCommentAttachment, setShowCommentAttachment] = useState({
    taskIndex: -1,
    commentIndex: -1,
    commentAttachIndex: -1,
    show: false,
  });

  const [showTaskAttachmentGallery, setShowTaskAttachmentGallery] = useState<{
    show: boolean;
    taskIndex: number;
    docIndex: number;
  }>({
    show: false,
    taskIndex: -1,
    docIndex: -1,
  });

  const [containerWidth, setContainerWidth] = useState(0);
  const [swiperContainerWidth, setSwiperContainerWidth] = useState(0);
  const [gridItemWidth, setGridItemWidth] = useState(420);
  const containerRef = useRef<any>(null);
  const swiperContainerRef = useRef<any>(null);
  const gridItemRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isClient = localStorage.getItem("sf_Role") == "true";
  const {
    totalIDs,
    currId,
    prevSelectedOpt,
    prevPayload,
    prevSearchType,
    prevKeyword,
    prevDateRange,
  } = (location?.state as any) ?? {};
  const commentUploadRef = useRef<any>(null);
  const isFirstPage = currIndex == 0;
  const isLastPage = currIndex == totalIDs?.length - 1;
  const toast = useToast();

  const { data: meData } = useQuery(GetmeDocument, {
    fetchPolicy: "network-only",
    onCompleted: (x) => {},
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  const myId = meData?.me.user
    ? meData?.me?.user?.id!
    : meData?.me?.company?.id!;
  const myCompanyId = meData?.me?.company?.id!;

  // get current site data
  const [getCurrSiteInfo, { data: siteInfo }] = useLazyQuery(SiteDocument, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const projectStatusArr = getCurrProjectStatus(data.site);
      const currPjStatus = convertAsCurrPjStatus(projectStatusArr);
      const currPjStatusBarObj = getCurrStatusDisplayName(projectStatusArr);

      setCurrPjStatus(currPjStatus);
      setCurrPjStatusBarObj(currPjStatusBarObj);

      // setCommentList(data.site?.tasks?.comments ? data.site?.tasks?.comments : [])
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  // delete comment function
  const deleteComment = (index: number, commentId: string) => {
    removeComment({
      variables: {
        commentId: commentId,
      },
    });
  };

  // add comment api
  const [addComment, { loading, error }] = useMutation(CreateCommentDocument, {
    onCompleted: (x) => {
      getCurrSiteInfo({
        variables: {
          id: totalIDs !== "noneed" ? totalIDs?.[currIndex] : currId,
        },
      });
      setTempComment("");
      return toast({
        position: "bottom-right",
        title: `One comment added`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Update Error: ${error}`,
        status: "error",
      });
    },
  });

  // remove comment api
  const [removeComment, {}] = useMutation(RemoveCommentDocument, {
    onCompleted: (x) => {
      getCurrSiteInfo({
        variables: {
          id: totalIDs !== "noneed" ? totalIDs?.[currIndex] : currId,
        },
      });
      return toast({
        position: "bottom-right",
        title: `One comment deleted`,
        status: "success",
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Unable to delete comment, Error: ${error}`,
        status: "error",
      });
    },
  });

  // init page
  useEffect(() => {
    if (totalIDs == undefined) {
      navigate("/project");
    }
    const indexOfCurr = totalIDs?.indexOf(currId);
    if (swiperContainerRef) {
      const swiperWidth = swiperContainerRef?.current?.clientWidth;

      setSwiperContainerWidth(swiperWidth);
    }

    if (gridItemRef) {
      const gridItemWidth = gridItemRef?.current?.clientWidth;

      setGridItemWidth(gridItemWidth);
    }

    setContainerWidth(containerRef?.current?.clientWidth);

    setCurrIndex(indexOfCurr);

    // getCurrSiteInfo({
    //   variables: {
    //     id: currId,
    //   },
    // });

    return () => {};
  }, []);

  // when change page
  useEffect(() => {
    getCurrSiteInfo({
      variables: {
        id: totalIDs !== "noneed" ? totalIDs?.[currIndex] : currId,
      },
    });

    getCalcData({
      variables: {
        siteId: totalIDs !== "noneed" ? totalIDs?.[currIndex] : currId,
      },
    });

    return () => {};
  }, [currIndex]);

  const statusTabs = [
    "All",
    "Application",
    "Installation",
    "Listed",
    "Contract end",
  ];

  const taskStatus = [
    { value: "current", name: t("TXT_Project_Current") },
    { value: "upcoming", name: t("TXT_Project_Upcoming") },
    { value: "ended", name: t("TXT_Project_Ended") },
  ];

  // add no image when images is not enough 5
  const appendEmptyGridItem = () => {
    // let newImagesArr = [...siteInfo?.site?.projectImages];
    let projectImage = siteInfo?.site?.projectImages
      ? [...siteInfo?.site?.projectImages]
      : [];

    if (projectImage.length < 5) {
      projectImage.push({
        filePath: "-",
        originalName: "-",
        url: "",
      });
    }

    return projectImage;
  };

  // const companyDocTestArr = () => {
  //   // let newImagesArr = [...siteInfo?.site?.projectImages];
  //   let companyDocuments = siteInfo?.site?.companyDocuments
  //     ? [...siteInfo?.site?.companyDocuments]
  //     : [];

  //   for (let index = companyDocuments.length; index < 5; index++) {
  //     companyDocuments.push({
  //       filePath: "-",
  //       originalName: "kewrfwe ger geg ege g eewufhkwhfew wefwefew wefwef",
  //       url: "none",
  //     });
  //   }
  //   return companyDocuments;
  // };

  // get task list by task status
  const getFilteredTaskList = () => {
    const curr = moment(new Date());

    return siteInfo?.site?.tasks
      ? siteInfo?.site?.tasks.filter((task) => {
          const start = moment(task.startTime);
          const end = moment(task.endTime).add(1, "day");

          switch (selectedTaskStatus) {
            case "current":
              return (
                curr.isBetween(start, end, "day") || curr.isSame(start, "day")
              );

            case "upcoming":
              return curr.isBefore(start, "day");
            case "ended":
              return curr.isAfter(end, "day");

            default:
              return true;
          }
        })
      : [];
  };

  const [updateComment] = useMutation(UpdateCommentDocument, {
    onCompleted: (x) => {
      // getAllComments({
      //   variables: {
      //     taskId: taskId,
      //   },
      // });

      toast({
        position: "bottom-right",
        title: `attachment uploaded`,
        status: "success",
      });
      getCurrSiteInfo({
        variables: {
          id: totalIDs !== "noneed" ? totalIDs?.[currIndex] : currId,
        },
      });
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: `Unable to add attachment, Error: ${error}`,
        status: "error",
      });
    },
  });

  const addCommentAttachment = async (event: any, commentObj: any) => {
    const uploadedDoc = await uploadDoc(event?.target?.files);

    const arrOfFiles = Array.from(event?.target?.files).map((f: any, i) => {
      return {
        // url: uploadedDoc.result[i].url,
        // url: uploadedDoc.result[i].url
        //   ? uploadedDoc.result[i].url
        //   : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Tkm2I2eZS-dnuXspUUvm8WiB07J0XE8f4A&usqp=CAU",
        filePath: uploadedDoc.result[i].filePath,
        originalName: uploadedDoc.result[i].originalName,
      };
    });

    const originFiles = commentObj.attachments.map((f: any) => {
      return {
        filePath: f.filePath,
        originalName: f.originalName,
      };
    });

    updateComment({
      variables: {
        input: {
          id: commentObj.id,
          message: commentObj.message,
          attachments: [...originFiles, ...arrOfFiles],
        },
      },
    });

    commentUploadRef.current.value = "";
  };

  const [getCalcData, {}] = useLazyQuery(ListInvestmentDocument, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log(data.listInvestments.nodes);
      setCalcData(data.listInvestments.nodes[0]);
    },
    onError: (error) => {
      return toast({
        position: "bottom-right",
        title: t("TXT_Error_general_err_message"),
        status: "error",
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>Solar Monster - Projects details</title>
      </Helmet>

      {/* header section */}
      <Flex
        flexDirection="column"
        flex={1}
        h="100vh"
        backgroundColor="#F7FAFA"
        ml="65px"
        overflowX="auto"
      >
        {/* top bar */}
        <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="65px"
        >
          <Flex>
            <Flex alignItems="center">
              <Flex
                onClick={() =>
                  navigate("/project", {
                    state: {
                      prevSelectedOpt: prevSelectedOpt,
                      prevPayload: prevPayload,
                      prevSearchType: prevSearchType,
                      prevKeyword: prevKeyword,
                      prevDateRange: prevDateRange,
                    },
                  })
                }
                cursor="pointer"
                alignItems="center"
                _hover={{ textDecoration: "underline" }}
              >
                <Icon as={FiArrowLeft} w="18px" h="18px" color="GREY" />
                <Text fontWeight="bold" color="GREY" ml="5px">
                  {t("TXT_Projects")}
                </Text>
              </Flex>
              <Flex fontWeight="bold">&nbsp; / {siteInfo?.site?.code}</Flex>
            </Flex>
          </Flex>
          <Flex></Flex>
          <RightCornerTool />
        </Flex>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          my="20px"
          mx="auto"
          maxW="calc(1920px - 40px)"
          width="100%"
          pl="10px"
          pr="20px"
        >
          {totalIDs !== "noneed" && (
            <Flex>
              <Flex
                pointerEvents={isFirstPage ? "none" : "auto"}
                cursor="pointer"
                width="35px"
                height="35px"
                borderRadius="25px"
                mx="10px"
                borderColor={isFirstPage ? "DISABLEDTEXT" : returnByTheme()}
                borderWidth="1px"
                justifyContent="center"
                alignItems="center"
                mr="5px"
                onClick={() => setCurrIndex(currIndex - 1)}
              >
                <AiOutlineLeft
                  color={isFirstPage ? "#CFCFCF" : returnByColor()}
                  width="30px"
                  height="30px"
                />
              </Flex>
              <Flex
                pointerEvents={isLastPage ? "none" : "auto"}
                cursor="pointer"
                width="35px"
                height="35px"
                borderRadius="25px"
                borderColor={isLastPage ? "DISABLEDTEXT" : returnByTheme()}
                borderWidth="1px"
                justifyContent="center"
                alignItems="center"
                ml="5px"
                onClick={() => setCurrIndex(currIndex + 1)}
              >
                <AiOutlineRight
                  color={isLastPage ? "#CFCFCF" : returnByColor()}
                  width="30px"
                  height="30px"
                />
              </Flex>
            </Flex>
          )}
          {!isClient && (
            <Button
              leftIcon={<FiEdit3 color="#ffffff" />}
              bg={returnByTheme()}
              variant="solid"
              borderRadius="18px"
              color="WT"
              fontWeight="normal"
              px="25px"
              onClick={() => {
                navigate(`/edit/project/${siteInfo?.site?.code}`, {
                  state: {
                    currId: siteInfo?.site?.id,
                  },
                });
              }}
            >
              {t("TXT_Project_Edit_project")}
            </Button>
          )}
        </Flex>

        <Flex
          maxW="calc(1920px - 40px)"
          mx="auto"
          flexDir="column"
          overflow="scroll"
          width="100%"
        >
          {/* main section */}
          <Flex justifyContent="space-between" gap="20px" mx="20px">
            <Flex
              width="75%"
              flexDir="column"
              gap="10px"
              // overflow="auto"
              // p="5px"
              ref={swiperContainerRef}
            >
              {(siteInfo?.site?.projectImages &&
                siteInfo?.site?.projectImages.length > 0 && (
                  <Grid
                    // h={gridItemWidth * 2 ?? "420px"}
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(6, 1fr)"
                    gap={4}
                  >
                    <GridItem
                      rowSpan={2}
                      colSpan={4}
                      overflow="hidden"
                      borderRadius="12px"
                      bg={`url(${siteInfo?.site?.projectImages[0]?.url})`}
                      bgSize="contain"
                      bgRepeat="no-repeat"
                      bgColor="DISABLEDBG"
                      bgPos="center"
                    >
                      {/* <Image
                          src={siteInfo?.site?.projectImages[0]?.url}
                          m="auto"
                          height="100%"
                          fit="contain"
                        /> */}
                    </GridItem>

                    {appendEmptyGridItem()
                      .slice(1, 5)
                      .map((image: any, i: number) => {
                        return (
                          <GridItem
                            key={i}
                            colSpan={1}
                            overflow="hidden"
                            borderRadius="12px"
                            style={{ aspectRatio: "1" }}
                            bg={`url(${
                              image?.url == ""
                                ? require("../../icons/general/noPhoto.png")
                                : image?.url
                            })`}
                            bgSize="contain"
                            bgRepeat="no-repeat"
                            bgColor="DISABLEDBG"
                            bgPos="center"
                          ></GridItem>
                        );
                      })}
                  </Grid>
                )) || (
                <Flex
                  height="40vh"
                  alignItems="center"
                  justifyContent="center"
                  bg="DISABLEDBG"
                  borderRadius="12px"
                >
                  <Flex color="DISABLEDTEXT" fontWeight="bold" fontSize="22px">
                    No photo
                  </Flex>
                </Flex>
              )}

              <Flex flexDir="column" borderBottom="1px solid #CFCFCF" py="10px">
                <Flex fontSize="28px" fontWeight="bold">
                  <Text>{siteInfo?.site?.name}</Text>
                  {/* <Text color="GREY">
                    {siteInfo?.site?.address?.subDistrict}
                  </Text> */}
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex flexDir="column">
                    <Flex fontWeight="medium">
                      <Text color="GREY">
                        {t("TXT_Project_Create_date")}: &nbsp;
                      </Text>
                      <Text>
                        {moment(siteInfo?.site?.createdAt).format(
                          "DD MMM, YYYY"
                        )}
                      </Text>
                    </Flex>
                    <Flex fontWeight="medium">
                      <Text color="GREY">{t("TXT_Address")}: &nbsp;</Text>
                      <Text>{siteInfo?.site?.address?.line}</Text>
                    </Flex>
                  </Flex>

                  <Flex>
                    {siteInfo?.site?.electricCompany?.type && (
                      <Flex
                        bg={
                          siteInfo?.site?.electricCompany?.type == "HKE"
                            ? "RD.1"
                            : "DB.3"
                        }
                        color="WT"
                        alignItems="center"
                        borderRadius="15px"
                        py="5px"
                        px="10px"
                        mr="10px"
                      >
                        <BsLightning />
                        <Text ml="5px">
                          {siteInfo?.site?.electricCompany?.type}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      // bg={typeOfSiteColor.[item.siteType.name as ]}
                      bg={
                        typeOfSiteColor[
                          siteInfo?.site?.siteType.name
                            .toUpperCase()
                            .replaceAll(
                              "-",
                              "_"
                            ) as keyof typeof typeOfSiteColor
                        ]
                      }
                      color="WT"
                      alignItems="center"
                      borderRadius="15px"
                      py="5px"
                      px="10px"
                    >
                      <FiHome />
                      <Text ml="5px">
                        {t(
                          `${
                            typeOfSiteText[
                              siteInfo?.site?.siteType.name
                                .toUpperCase()
                                .replaceAll(
                                  "-",
                                  "_"
                                ) as keyof typeof typeOfSiteText
                            ]
                          }`
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/* documents section */}
              {siteInfo?.site?.companyDocuments && (
                <Flex
                  flexDir="column"
                  borderBottom="1px solid #CFCFCF"
                  pt="5px"
                  pb="10px"
                >
                  <Flex mb="5px" fontWeight="bold">
                    {t("TXT_Project_Company_documents")}
                  </Flex>
                  <Flex flex={1} gap="10px" py="5px" overflow="hidden">
                    {(siteInfo.site.companyDocuments.length > 4 && (
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={4}
                        slidesPerGroup={4}
                        loopFillGroupWithBlank={true}
                        draggable={false}
                        initialSlide={0}
                      >
                        {siteInfo?.site?.companyDocuments.map(
                          (document: any, index: number) => {
                            return (
                              <SwiperSlide key={index}>
                                {/* <Flex flex={1} alignItems="center"> */}
                                <Flex
                                  onClick={() => {
                                    setDocumentGalleryIndxe(index);
                                    setShowDocumentsGallery(true);
                                  }}
                                  p="10px"
                                  borderRadius="15px"
                                  borderColor={returnByTheme()}
                                  borderWidth="1px"
                                  key={index}
                                  cursor="pointer"
                                  // width={
                                  //   swiperContainerWidth
                                  //     ? swiperContainerWidth / 4
                                  //     : "fit-content"
                                  // }
                                >
                                  <Flex
                                    alignItems="center"
                                    gap="10px"
                                    overflow="hidden"
                                  >
                                    <Flex flex={1}>
                                      <PDFIcon />
                                    </Flex>

                                    <Flex
                                      flexDir="column"
                                      overflow="hidden"
                                      // width="100%"
                                    >
                                      <Text noOfLines={1}>
                                        {document.originalName}
                                      </Text>
                                      {/* <Text noOfLines={1}>----</Text> */}
                                    </Flex>
                                  </Flex>
                                  {/* </Flex> */}
                                </Flex>
                              </SwiperSlide>
                            );
                          }
                        )}
                      </Swiper>
                    )) || (
                      <>
                        {siteInfo?.site?.companyDocuments.map(
                          (document: any, index: number) => {
                            return (
                              <Flex key={index}>
                                {/* <Flex flex={1} alignItems="center"> */}
                                <Flex
                                  onClick={() => {
                                    setDocumentGalleryIndxe(index);
                                    setShowDocumentsGallery(true);
                                  }}
                                  p="10px"
                                  borderRadius="15px"
                                  borderColor={returnByTheme()}
                                  borderWidth="1px"
                                  key={index}
                                  cursor="pointer"
                                  width={
                                    swiperContainerWidth
                                      ? swiperContainerWidth / 4
                                      : "fit-content"
                                  }
                                >
                                  <Flex
                                    alignItems="center"
                                    gap="10px"
                                    overflow="hidden"
                                  >
                                    <Flex flex={1}>
                                      <PDFIcon />
                                    </Flex>

                                    <Flex
                                      flexDir="column"
                                      overflow="hidden"
                                      // width="100%"
                                    >
                                      <Text noOfLines={1}>
                                        {document.originalName}
                                      </Text>
                                      {/* <Text noOfLines={1}>----</Text> */}
                                    </Flex>
                                  </Flex>
                                  {/* </Flex> */}
                                </Flex>
                              </Flex>
                            );
                          }
                        )}
                      </>
                    )}
                  </Flex>
                </Flex>
              )}

              {/* ele info section */}
              <Flex justifyContent="space-between" py="10px">
                <Flex fontWeight="bold" pr="20px">
                  {t("TXT_Project_Electricity_details")}
                </Flex>
                <Flex flexDir="column" pr="20px" fontWeight="bold">
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_FiT_application_code")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails?.fitApplicationCode ??
                        "-"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_Meter_id")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails?.electricMeters.filter(
                        (meter) => {
                          return meter.isFit == false;
                        }
                      )[0]?.code ?? "-"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_Meter_id_fit")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails?.electricMeters.filter(
                        (meter) => {
                          return meter.isFit == true;
                        }
                      )[0]?.code ?? "-"}
                    </Text>
                  </Flex>
                </Flex>
                <Flex flexDir="column" pr="20px" fontWeight="bold">
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_Fit_rate")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails?.fitRate ?? "-"}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_Energy_output1")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails
                        ?.energyOutputByCompany ?? "-"}{" "}
                      kW
                      {/* ({siteInfo?.site?.electricCompany?.type}) */}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text color="GREY">
                      {t("TXT_Project_Energy_output2")}: &nbsp;
                    </Text>
                    <Text>
                      {siteInfo?.site?.electricityDetails
                        ?.energyOutputByContractor ?? "-"}{" "}
                      kW(Contractor)
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              {/* project task section */}

              {siteInfo?.site?.tasks && siteInfo?.site?.tasks?.length > 0 && (
                <Flex flexDir="column" borderTop="1px solid #CFCFCF">
                  <Flex fontWeight="bold" mb="10px">
                    {t("TXT_Project_Project_tasks")}
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    mb="10px"
                    alignItems="center"
                  >
                    <Flex
                      borderRadius="18px"
                      borderColor={returnByTheme()}
                      bg="WT"
                      p="3px"
                      borderWidth="1px"
                      gap="2px"
                    >
                      {taskStatus.map((ts, i) => {
                        return (
                          <Flex
                            cursor="pointer"
                            key={i}
                            onClick={() => {
                              setSelectedTaskStatus(ts.value);
                            }}
                            py="2px"
                            px="8px"
                            borderRadius="18px"
                            borderWidth={
                              selectedTaskStatus == ts.value ? "1px" : "0"
                            }
                            bg={
                              selectedTaskStatus == ts.value
                                ? returnByTheme()
                                : "none"
                            }
                            color={
                              selectedTaskStatus == ts.value ? "WT" : "GREY"
                            }
                          >
                            {ts.name}
                          </Flex>
                        );
                      })}
                    </Flex>
                    <Flex>
                      <Text color="GREY">{t("TXT_Project_Today")}: &nbsp;</Text>
                      <Text>
                        {moment(new Date()).format("dddd, DD MMM YYYY")}
                      </Text>
                    </Flex>
                  </Flex>

                  {(getFilteredTaskList()?.length > 0 && (
                    <>
                      {getFilteredTaskList()?.map((task, index) => {
                        const isShowComment =
                          showComment.show == true &&
                          showComment.index == index;
                        const isShowAttactment =
                          showAttachment.show == true &&
                          showAttachment.index == index;

                        const priorityText =
                          task.priority == "HIGH_PRIORITY"
                            ? "high"
                            : task.priority == "LOW_PRIORITY"
                            ? "low"
                            : "none";
                        return (
                          <Flex
                            key={index}
                            py="20px"
                            boxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.05)"
                            borderRadius="8px"
                            borderWidth="1px"
                            flexDir="column"
                            my="20px"
                            bg={"WT"}
                          >
                            <Flex
                              flex={1}
                              justifyContent="space-between"
                              alignItems="center"
                              borderBottom="1px solid #CFCFCF"
                              px="20px"
                              pb="10px"
                            >
                              <Flex>
                                <Flex fontWeight="bold" fontSize="18px">
                                  {task.title}
                                </Flex>
                                {priorityText !== "none" && (
                                  <Flex
                                    // flex={1}
                                    px="5px"
                                    bg={
                                      priorityText == "high"
                                        ? "rgba(231, 27, 43, 0.25)"
                                        : priorityText == "low"
                                        ? "rgba(16, 62, 98, 0.25)"
                                        : "rgba(247, 152, 70, 0.25)"
                                    }
                                    ml="20px"
                                    borderRadius="5px"
                                  >
                                    <Flex
                                      gap="5px"
                                      // color={returnByTheme()}
                                      alignItems={"center"}
                                      color={
                                        priorityText == "high"
                                          ? "rgba(231, 27, 43, 1)"
                                          : priorityText == "low"
                                          ? "rgba(16, 62, 98, 1)"
                                          : "rgba(247, 152, 70, 1)"
                                      }
                                    >
                                      <FiFlag />
                                      <Flex fontSize="12px">
                                        {t(
                                          `TXT_Project_${priorityText}_priority`
                                        )}
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                )}
                              </Flex>

                              <Flex>
                                <Text color="GREY">
                                  {t("TXT_Project_Period")}: &nbsp;
                                </Text>
                                <Text>
                                  {moment(task.startTime).format("DD MMM YYYY")}{" "}
                                  - {moment(task.endTime).format("DD MMM YYYY")}
                                </Text>
                              </Flex>
                            </Flex>

                            <Flex
                              flex={1}
                              justifyContent="space-between"
                              alignItems="center"
                              px="20px"
                              py="10px"
                            >
                              <Flex color="GREY">{task.description}</Flex>
                              {!isClient && (
                                <Flex>
                                  <Flex
                                    color={returnByTheme()}
                                    cursor="pointer"
                                    alignItems="center"
                                    onClick={() => {
                                      setShowComment({
                                        index: index,
                                        show:
                                          index == showComment.index
                                            ? !showComment.show
                                            : true,
                                      });
                                      setShowAttachment({
                                        index: -1,
                                        show: false,
                                      });
                                    }}
                                    mr="30px"
                                  >
                                    {t("TXT_Project_Comments")}
                                    {isShowComment ? (
                                      <AiFillCaretUp />
                                    ) : (
                                      <AiFillCaretDown />
                                    )}
                                  </Flex>
                                  <Flex
                                    color={returnByTheme()}
                                    cursor="pointer"
                                    alignItems="center"
                                    onClick={() => {
                                      setShowAttachment({
                                        index: index,
                                        show:
                                          index == showAttachment.index
                                            ? !showAttachment.show
                                            : true,
                                      });
                                      setShowComment({
                                        index: -1,
                                        show: false,
                                      });
                                    }}
                                  >
                                    {t("TXT_Project_Attachments")}
                                    {isShowAttactment ? (
                                      <AiFillCaretUp />
                                    ) : (
                                      <AiFillCaretDown />
                                    )}
                                  </Flex>
                                </Flex>
                              )}
                            </Flex>

                            {/* comment section */}
                            {isShowComment && (
                              <>
                                {/* add comment section */}
                                <Flex py="20px" mx="20px">
                                  <Flex
                                    position={"relative"}
                                    marginRight="10px"
                                    flex={0.3}
                                    alignItems="center"
                                    flexDirection={"column"}
                                  >
                                    <Flex
                                      width="45px"
                                      minHeight="45px"
                                      maxHeight="45px"
                                      bg={"red.300"}
                                      borderRadius={"50%"}
                                      justifyContent="center"
                                      alignItems={"center"}
                                    >
                                      <Flex
                                        textAlign={"center"}
                                        fontWeight={"light"}
                                        color={returnByTheme()}
                                      >
                                        YOU
                                      </Flex>
                                    </Flex>
                                    {/* <Flex
                            width={"1px"}
                            height={"100%"}
                            my="10px"
                            bg={returnByTheme()}
                          ></Flex> */}
                                  </Flex>

                                  <Flex flexDirection="column" flex={3}>
                                    <Box
                                      border={"1px solid"}
                                      borderColor={returnByTheme()}
                                      borderRadius={"5px"}
                                      px="10px"
                                      pb="5px"
                                    >
                                      <Textarea
                                        border={"none"}
                                        outline={"none"}
                                        boxShadow={"none"}
                                        focusBorderColor={"none"}
                                        value={tempComment}
                                        resize={"none"}
                                        minHeight="150px"
                                        placeholder={t(
                                          "TXT_Project_leave_a_comment"
                                        )}
                                        _placeholder={{ color: "DISABLEDTEXT" }}
                                        maxLength={150}
                                        px="0"
                                        pb="5px"
                                        onChange={(e) => {
                                          setTempComment(e.target.value);
                                        }}
                                      />
                                      <Flex height="20px">
                                        <Flex
                                          justifyContent={"space-between"}
                                          width="100%"
                                          alignItems={"center"}
                                        >
                                          <Flex
                                            onClick={() => {
                                              addComment({
                                                variables: {
                                                  input: {
                                                    taskId: task.id,
                                                    userId: myId, // todo allow user add comment
                                                    message: tempComment,
                                                  },
                                                },
                                              });
                                            }}
                                            cursor="pointer"
                                          >
                                            <SendIcon />
                                          </Flex>
                                          <Box>0/150</Box>
                                        </Flex>
                                      </Flex>
                                    </Box>
                                    <Box fontSize="10px" color="GREY">
                                      {t("TXT_Project_Comment_input_hints")}
                                    </Box>
                                  </Flex>
                                </Flex>

                                {/* comment history section */}
                                {task?.comments?.map(
                                  (c: any, commentIndex: number) => {
                                    const isSameUser = c?.user?.id == myId;
                                    const newUserObj =
                                      c.user.__typename == "Company"
                                        ? {
                                            name: c.user?.companyName,
                                            email: c.user?.companyEmail,
                                            url: c.user?.profileImage?.url,
                                          }
                                        : {
                                            name: c.user?.userName,
                                            email: c.user?.userEmail,
                                            url: c.user?.profileImage?.url,
                                          };
                                    return (
                                      <Flex key={commentIndex} mx="20px">
                                        <Flex
                                          position={"relative"}
                                          marginRight="10px"
                                          flex={0.3}
                                          alignItems="center"
                                          flexDirection={"column"}
                                        >
                                          <Flex
                                            position="absolute"
                                            width="45px"
                                            minHeight="45px"
                                            maxHeight="45px"
                                            borderRadius={"50%"}
                                            border="1px solid #f0f0f0"
                                            justifyContent="center"
                                            alignItems={"center"}
                                            bg={
                                              newUserObj?.url && !isSameUser
                                                ? `url(${newUserObj?.url})`
                                                : randomBgColor()
                                            }
                                            bgSize="contain"
                                            bgPos="center"
                                            bgRepeat="no-repeat"
                                          >
                                            {(!newUserObj?.url ||
                                              isSameUser) && (
                                              <Flex
                                                textAlign={"center"}
                                                fontWeight={"light"}
                                                color={"WT"}
                                                // color={randomColor()}
                                              >
                                                {isSameUser
                                                  ? "YOU"
                                                  : convertAsAvatarTitle(
                                                      newUserObj.name
                                                    )}
                                              </Flex>
                                            )}
                                          </Flex>
                                          {commentIndex !==
                                            task?.comments.length - 1 && (
                                            <Flex
                                              width={"1px"}
                                              height={"100%"}
                                              bg={returnByTheme()}
                                            ></Flex>
                                          )}
                                        </Flex>
                                        <Flex
                                          flex={3}
                                          flexDirection={"column"}
                                          mb="20px"
                                        >
                                          <Flex
                                            color={"GREY"}
                                            fontSize={"14px"}
                                            justifyContent={"space-between"}
                                            alignItems={"flex-end"}
                                            mb="5px"
                                          >
                                            <Flex>
                                              {newUserObj.name} ,{" "}
                                              {moment(c.createdAt).format(
                                                "DD MMM, YYYY"
                                              )}
                                            </Flex>
                                            {isSameUser &&
                                              c.message !== null && (
                                                <Flex gap="10px">
                                                  <Input
                                                    display={"none"}
                                                    ref={commentUploadRef}
                                                    multiple={true}
                                                    type={"file"}
                                                    accept={".jpg,.png,.pdf"}
                                                    onChange={(e) => {
                                                      addCommentAttachment(e, {
                                                        id: task?.comments[
                                                          uploadRefIndex
                                                        ].id,
                                                        message:
                                                          task?.comments[
                                                            uploadRefIndex
                                                          ].message,
                                                        attachments:
                                                          task?.comments[
                                                            uploadRefIndex
                                                          ].attachments,
                                                      });
                                                    }}
                                                  />
                                                  <UploadAttachIcon
                                                    cursor="pointer"
                                                    onClick={() => {
                                                      commentUploadRef?.current?.click();
                                                      setUploadRefIndex(
                                                        commentIndex
                                                      );
                                                    }}
                                                  />
                                                  <TrashIcon
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                      deleteComment(
                                                        commentIndex,
                                                        c.id
                                                      );
                                                    }}
                                                  />
                                                </Flex>
                                              )}
                                          </Flex>
                                          <Flex
                                            p="10px"
                                            borderRadius="10px"
                                            background={"DISABLEDBG"}
                                          >
                                            {c?.message ? (
                                              c?.message
                                            ) : (
                                              <Text color="DISABLEDTEXT">
                                                {c?.user.name}{" "}
                                                {t(
                                                  "TXT_Project_Deleted_comment_desc"
                                                )}
                                              </Text>
                                            )}
                                          </Flex>
                                          {/* commentt attachment list section */}
                                          {c.attachments?.map(
                                            (
                                              a: any,
                                              attachmentIndex: number
                                            ) => {
                                              // const imageUrl = URL.createObjectURL(a?.file);
                                              const extensionName =
                                                a?.originalName
                                                  .split(".")
                                                  .pop();
                                              return (
                                                <Flex
                                                  key={attachmentIndex}
                                                  justifyContent={
                                                    "space-between"
                                                  }
                                                  alignItems={"center"}
                                                  borderBottomColor={
                                                    "DISABLEDBG"
                                                  }
                                                  py="10px"
                                                >
                                                  <Flex
                                                    mr={"10px"}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                      setShowCommentAttachment({
                                                        taskIndex: index,
                                                        commentIndex:
                                                          commentIndex,
                                                        commentAttachIndex:
                                                          attachmentIndex,
                                                        show: true,
                                                      });
                                                    }}
                                                  >
                                                    {(extensionName !==
                                                      "pdf" && (
                                                      <Image
                                                        width={"30px"}
                                                        height={"30px"}
                                                        src={a.url}
                                                        borderRadius={"5px"}
                                                        fit={"cover"}
                                                        loading={"lazy"}
                                                      />
                                                    )) || <PDFIcon />}

                                                    <Flex
                                                      alignItems={"center"}
                                                      ml="5px"
                                                    >
                                                      <Text
                                                        ml="5px"
                                                        noOfLines={1}
                                                        textOverflow="ellipsis"
                                                        maxW="250px"
                                                      >
                                                        {a?.originalName}
                                                      </Text>
                                                    </Flex>
                                                  </Flex>
                                                  <TrashIcon
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                      // get latest comment

                                                      const remainingAttachArr =
                                                        task?.comments[
                                                          commentIndex
                                                        ]?.attachments!.filter(
                                                          (
                                                            f: any,
                                                            selectedIndex: number
                                                          ) => {
                                                            return (
                                                              selectedIndex !==
                                                              attachmentIndex
                                                            );
                                                          }
                                                        );

                                                      updateComment({
                                                        variables: {
                                                          input: {
                                                            id: task?.comments[
                                                              commentIndex
                                                            ].id,
                                                            message:
                                                              task?.comments[
                                                                commentIndex
                                                              ].message ?? "",
                                                            attachments:
                                                              remainingAttachArr.map(
                                                                (f: any) => {
                                                                  return {
                                                                    filePath:
                                                                      f.filePath,
                                                                    originalName:
                                                                      f.originalName,
                                                                  };
                                                                }
                                                              ),
                                                          },
                                                        },
                                                      });
                                                    }}
                                                  />
                                                </Flex>
                                              );
                                            }
                                          )}
                                        </Flex>
                                      </Flex>
                                    );
                                  }
                                )}
                              </>
                            )}

                            {/* attachment section */}
                            {isShowAttactment && (
                              <Flex
                                borderTop="1px solid #CFCFCF"
                                mx="20px"
                                pt="20px"
                                gap="10px"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                              >
                                {(task.attachments &&
                                  task.attachments?.length > 0 && (
                                    <>
                                      {task?.attachments?.map((d, i) => {
                                        return (
                                          <Flex alignItems="center" key={i}>
                                            <Flex
                                              onClick={() =>
                                                setShowTaskAttachmentGallery({
                                                  show: true,
                                                  taskIndex: index,
                                                  docIndex: i,
                                                })
                                              }
                                              p="10px"
                                              borderRadius="15px"
                                              borderColor={returnByTheme()}
                                              borderWidth="1px"
                                              // width="100%"
                                              // maxW="300px"
                                              key={i}
                                              cursor="pointer"
                                            >
                                              <Flex
                                                alignItems="center"
                                                gap="10px"
                                                // overflow="hidden"
                                              >
                                                <Flex flex={1}>
                                                  <PDFIcon />
                                                </Flex>

                                                <Flex
                                                  flexDir="column"
                                                  // width="100%"
                                                >
                                                  <Text
                                                    noOfLines={1}
                                                    textOverflow="ellipsis"
                                                    maxW="300px"
                                                  >
                                                    {d.originalName}
                                                  </Text>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        );
                                      })}
                                    </>
                                  )) || (
                                  <Flex>{t("TXT_Project_No_attachments")}</Flex>
                                )}
                              </Flex>
                            )}
                          </Flex>
                        );
                      })}
                    </>
                  )) || <Flex>{t("TXT_Project_No_results")}</Flex>}
                </Flex>
              )}
            </Flex>

            {/* chart section in right */}
            <Flex
              minWidth="450px"
              width="25%"
              flexDir="column"
              gap="10px"
              overflow="auto"
              p="5px"
              ref={containerRef}
            >
              {!calcData && (
                <ProjectCalculatorPanel
                  siteId={siteInfo?.site?.id}
                  investorId={siteInfo?.site?.investor.id}
                  sharingRatioData={siteInfo?.site?.profitShares}
                  fitRate={siteInfo?.site?.electricityDetails?.fitRate}
                />
              )}

              <ProjectSharingPieChart siteInfo={siteInfo} />

              {/* progress section in right */}
              {!siteInfo?.site?.sitePower && (
                <ProjectProgressBarSection
                  siteInfo={siteInfo}
                  currPjStatusBarObj={currPjStatusBarObj}
                  containerWidth={containerWidth}
                />
              )}

              {/* calc data section in right */}
              <ProjectCalcOverviewSection
                siteId={siteInfo?.site?.id}
                calcData={calcData}
              />

              {/* ele section in right */}
              {siteInfo?.site?.sitePower && (
                <ProjectPowerGenSection
                  siteInfo={siteInfo}
                  currPjStatusBarObj={currPjStatusBarObj}
                />
              )}

              {/* investor section in right */}
              <ProjectInvestorSection siteInfo={siteInfo} />

              {/* pm section in right */}
              {siteInfo?.site?.projectManagers &&
                siteInfo?.site?.projectManagers?.length > 0 && (
                  <ProjectStaffSection siteInfo={siteInfo} />
                )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* image gallery modal */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showImagesGallery}
        onClose={() => setShowImagesGallery(false)}
        isCentered
        // scrollBehavior="inside"
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: 0, overflow: "hidden" }}>
            <DocumentSwiper
              onClose={() => setShowImagesGallery(false)}
              files={siteInfo?.site?.projectImages}
              defaultIndex={imageGalleryIndex}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* document gallery modal */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showDocumentsGallery}
        onClose={() => setShowDocumentsGallery(false)}
        isCentered
        scrollBehavior="inside"
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: 0, overflow: "hidden" }}>
            <DocumentSwiper
              onClose={() => setShowDocumentsGallery(false)}
              files={siteInfo?.site?.companyDocuments}
              defaultIndex={documentGalleryIndex}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* task attachment gallery modal */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showTaskAttachmentGallery.show}
        onClose={() =>
          setShowTaskAttachmentGallery({
            show: false,
            taskIndex: -1,
            docIndex: -1,
          })
        }
        isCentered
        // scrollBehavior="inside"
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: 0, overflow: "hidden" }}>
            <DocumentSwiper
              onClose={() =>
                setShowTaskAttachmentGallery({
                  show: false,
                  taskIndex: -1,
                  docIndex: -1,
                })
              }
              files={
                siteInfo?.site?.tasks[showTaskAttachmentGallery.taskIndex]
                  ?.attachments!
              }
              defaultIndex={showTaskAttachmentGallery.docIndex}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* task comment attachment gallery modal */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showCommentAttachment.show}
        onClose={() =>
          setShowCommentAttachment({
            show: false,
            taskIndex: -1,
            commentIndex: -1,
            commentAttachIndex: -1,
          })
        }
        isCentered
        // scrollBehavior="inside"
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: 0, overflow: "hidden" }}>
            <DocumentSwiper
              onClose={() =>
                setShowCommentAttachment({
                  show: false,
                  taskIndex: -1,
                  commentIndex: -1,
                  commentAttachIndex: -1,
                })
              }
              files={
                siteInfo?.site?.tasks[showCommentAttachment.taskIndex]
                  ?.comments[showCommentAttachment.commentIndex].attachments
              }
              defaultIndex={showCommentAttachment.commentAttachIndex}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProjectDetail;
