import { Box, chakra, Flex, Text, Divider, GridItem, Grid, useToast, Spinner } from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ResponsiveContainer } from "recharts";
import ProjectKindsRow from "./ProjectKindsRow";
import { returnByColor } from "src/utils/util";
import ProjectTotalDataRow from "./ProjectTotalDataRow";
import ProjectMappingRow from "./ProjectMappingRow";
import AverageEfficiencyPieChart from "./AverageEfficiencyPieChart";
import ProductionQuantityBarChart from "./ProductionQuantityBarChart";
import GenerationProfitBarLineChart from "./GenerationProfitBarLineChart";
import moment from "moment";
import WeatherForecastRow from "./WeatherForecastRow";
import SunsetAndSunshineRow from "./SunsetAndSunshineRow";
import InvoicesRow from "./InvoicesRow";
import CurrentTaskRow from "./CurrentTaskRow";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
	AccumulatedSitePowerDocument,
	ElectricCompanyInfoCompany,
	GetDashboardPieOrChartDataByContractorIdDocument,
	GetDashboardPieOrChartDataByContractorIdQuery,
	GetDashboardPieOrChartDataByInvestorIdDocument,
	GetDashboardPieOrChartDataByInvestorIdQuery,
	StoredType,
} from "src/graphql/generated";
import CopyRightBar from "src/components/CopyRightBar";
import RightCornerTool from "src/components/RightCornerTool";
import GeneralTopHeader from "src/components/GeneralTopHeader";
const isInvestor = localStorage.getItem("sf_Investor") == "true";

let singlePieData: Array<{ name: string; value: number }> = [{ name: "Waiting...", value: 1 }];

let singleBarData: Array<{ name: string; profit: number }> = [{ name: "Waiting...", profit: 1 }];

const Dashboard = () => {
	const [showHKE, setShowHKE] = useState(false);
	const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM"));

	const pieContainerRef = useRef<any>();
	const [width, setWidth] = useState<any>(0);
	useLayoutEffect(() => {
		setWidth(pieContainerRef?.current?.offsetWidth);
	}, []);

	const [barLineChartData, setbarLineChartData] = useState<any>([]);

	const { t } = useTranslation();
	const toast = useToast();

	const arrOfMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	let query = isInvestor ? GetDashboardPieOrChartDataByInvestorIdDocument : GetDashboardPieOrChartDataByContractorIdDocument;

	const { data: PieOrBarData } = useQuery(query, {
		onCompleted: (data: GetDashboardPieOrChartDataByInvestorIdQuery & GetDashboardPieOrChartDataByContractorIdQuery) => {
			singlePieData = isInvestor
				? data?.dashboardSitePowersByInvestorIdAndSiteType?.map((d) => {
						return {
							name: t(`TXT_Option_${d.name.replaceAll("-", "_").toUpperCase()}`),
							value: d.siteNumber,
						};
				  }) ?? []
				: data?.dashboardSitePowersByContractorIdAndSiteType?.map((d) => {
						return {
							name: t(`TXT_Option_${d.name.replaceAll("-", "_").toUpperCase()}`),
							value: d.siteNumber,
						};
				  }) ?? [];

			singleBarData = isInvestor
				? data?.dashboardSitePowersByInvestorIdAndSiteType?.map((d) => {
						return {
							name: t(`TXT_Option_${d.name.replaceAll("-", "_").toUpperCase()}`),
							profit: d.totalPowerGeneration,
						};
				  }) ?? []
				: data?.dashboardSitePowersByContractorIdAndSiteType?.map((d) => {
						return {
							name: t(`TXT_Option_${d.name.replaceAll("-", "_").toUpperCase()}`),
							profit: d.totalPowerGeneration,
						};
				  }) ?? [];
		},
		onError: (error) => {
			return toast({
				position: "bottom-right",
				title: `Fail to get chart data`,
				status: "error",
			});
		},
	});

	const autoGenMothArr = (data: any, month: string) => {
		let clpData = [...data];
		const daysInMonths = moment(month, "YYYY-MM").daysInMonth();

		if (clpData.length > 0) {
			const firstDate = moment(data[0].date).format("DD");

			if (firstDate !== "1") {
				// not start from 1 , need to fill prev days
				for (let index = 0; index < Number(firstDate) - 1; index++) {
					clpData.unshift({
						name: (index + 1).toString(),
						profit: 0,
						generate: 0,
					});
				}
			}

			for (let index = clpData.length; index < daysInMonths; index++) {
				clpData.push({
					name: (index + 1).toString(),
					profit: 0,
					generate: 0,
				});
			}

			const rightIndexClpData = clpData.map((d, i) => {
				return {
					name: (i + 1).toString(),
					profit: d.profit,
					generate: d.generate,
				};
			});

			return rightIndexClpData;
		} else {
			for (let index = 0; index < daysInMonths; index++) {
				clpData.push({
					name: (index + 1).toString(),
					profit: 0,
					generate: 0,
				});
			}
			return clpData;
		}
	};

	const fillEveryMonth = (data: any) => {
		let hkeData = [...data];

		if (hkeData.length > 0) {
			const firstMonth = moment(data[0].date).format("M");

			console.log(firstMonth);

			if (firstMonth !== "1") {
				// not start from 1 , need to fill prev months
				for (let index = 0; index < Number(firstMonth) - 1; index++) {
					hkeData.unshift({
						name: index,
						profit: 0,
						generate: 0,
					});
				}
			}

			for (let index = hkeData.length; index < 12; index++) {
				hkeData.push({
					name: arrOfMonths[index],
					profit: 0,
					generate: 0,
				});
			}

			const rightIndexHkeData = hkeData.map((d, i) => {
				return {
					name: arrOfMonths[i],
					profit: d.profit,
					generate: d.generate,
				};
			});

			return rightIndexHkeData;
		} else {
			for (let index = hkeData.length; index < 12; index++) {
				hkeData.push({
					name: arrOfMonths[index],
					profit: 0,
					generate: 0,
				});
			}
			return hkeData;
		}
	};

	const [getbarLineData, { data: barLineData, loading: loadingbarLineChart }] = useLazyQuery(AccumulatedSitePowerDocument, {
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (showHKE) {
				const originalArr = data.accumulatedSitePower.nodes.map((d, i) => {
					return {
						name: arrOfMonths[i],
						profit: d?.revenue,
						generate: d?.generatedKwh,
						date: d?.date,
					};
				});
				const newArrForHKE = fillEveryMonth(originalArr);

				setbarLineChartData(newArrForHKE);
			} else {
				const originalArr = data.accumulatedSitePower.nodes.map((d, i) => {
					return {
						name: (i + 1).toString(),
						profit: d?.revenue,
						generate: d?.generatedKwh,
						date: d?.date,
					};
				});

				const newArrForCLP = autoGenMothArr(originalArr, selectedDate);

				setbarLineChartData(newArrForCLP);
			}
		},
		onError: (error) => {
			return toast({
				position: "bottom-right",
				title: `Fail to get chart data`,
				status: "error",
			});
		},
	});

	useEffect(() => {
		if (showHKE) {
			getbarLineData({
				variables: {
					filter: {
						startDate: moment(selectedDate).format("YYYY-MMM"),
						// endDate: moment(selectedDate).format("YYYY-12"),
						endDate: moment(selectedDate).add(1, "year").format("YYYY-MMM"),
						electricCompanyType: ElectricCompanyInfoCompany.Hke,
						storedType: StoredType.Accumulate,
					},
					limit: 31,
				},
			});
		} else {
			getbarLineData({
				variables: {
					filter: {
						startDate: moment(selectedDate).format("YYYY-MMM"),
						endDate: moment(selectedDate).add(1, "month").format("YYYY-MMM"),
						electricCompanyType: ElectricCompanyInfoCompany.Clp,
						storedType: StoredType.Accumulate,
					},
					limit: 31,
				},
			});
		}

		return () => {};
	}, [selectedDate, showHKE]);

	//init load
	useEffect(() => {
		getbarLineData({
			variables: {
				filter: {
					startDate: moment(selectedDate).format("YYYY-MMM"),
					endDate: moment(selectedDate).add(1, "month").format("YYYY-MMM"),
					electricCompanyType: ElectricCompanyInfoCompany.Clp,
					storedType: StoredType.Accumulate,
				},
				limit: 31,
			},
		});

		return () => {};
	}, []);

	return (
		<>
			<Helmet>
				<title>Solar Monster - Dashboard</title>
			</Helmet>
			{/* header section */}
			<Flex flexDirection="column" flex={1} h="100vh" backgroundColor="#F7FAFA" ml="65px" overflowX="auto">
				<GeneralTopHeader headerTitle={t("TXT_Dashboard")} hadRightCornerTools />
				{/* <Flex
          backgroundColor="WT"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="2px 2px 2px 2px rgba(0, 0, 0, 0.05)"
          px="20px"
          minH="60px"
        >
          <Flex>
            <Text fontWeight="bold">{t("TXT_Dashboard")}</Text>
          </Flex>
          <Flex></Flex>
          <RightCornerTool />
        </Flex> */}

				<Flex flexDirection="column" marginTop={"25px"} maxW="1920px" mx="auto" px="20px" width="100%" mb="70px">
					<ProjectKindsRow />
					<Flex height="20px"></Flex>
					<ProjectTotalDataRow />
					<Flex height="20px"></Flex>
					<ProjectMappingRow />
					<Flex height="20px"></Flex>
					{/* chart section */}
					<Grid templateColumns="1fr 1fr 1fr" gap="20px">
						<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" overflow="auto" ref={pieContainerRef} height={width}>
							{/* <ResponsiveContainer
                width={"calc(100% / 3)"}
                height={"calc(100% / 3)"}
              > */}
							<AverageEfficiencyPieChart ratioData={singlePieData} />
							{/* </ResponsiveContainer> */}
						</GridItem>

						<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" overflow="auto" height={width}>
							<Flex fontWeight="bold" mb="30px">
								{t("TXT_Dashboard_bar_chart_title")}
							</Flex>
							<Flex overflowX="scroll">
								<ProductionQuantityBarChart singleBarData={singleBarData} containerWidth={width} />
							</Flex>
						</GridItem>
						<GridItem p="10px" borderRadius="8px" boxShadow="0px 1px 1px 2px rgba(0, 0, 0, 0.05)" bg="WT" height={width} overflowX="hidden">
							{(loadingbarLineChart && (
								<Flex flex={1} justifyContent="center" py="20px">
									<Spinner thickness="4px" speed="2s" emptyColor="gray.200" color={"blue.500"} size="sm" />
								</Flex>
							)) || (
								<GenerationProfitBarLineChart
									data={barLineChartData}
									showHKE={showHKE}
									selectedDate={selectedDate}
									updateDate={(date: string) => setSelectedDate(date)}
									selectEleCompany={(show: boolean) => {
										setSelectedDate(moment(new Date()).format("YYYY-M"));
										setShowHKE(show);
									}}
									containerWidth={width}
								/>
							)}
						</GridItem>
					</Grid>
					<Flex height="20px"></Flex>
					<WeatherForecastRow />
					<Flex height="20px"></Flex>
					<SunsetAndSunshineRow />
					<Flex height="20px"></Flex>
					<InvoicesRow />
					<Flex height="20px"></Flex>
					<CurrentTaskRow />
				</Flex>
				<CopyRightBar />
			</Flex>
		</>
	);
};

export default Dashboard;
